<template>
  <div id="dashboard">
    <v-container fluid>
      <v-row justify="center">
        <v-col class="d-flex child-flex" xs="12" sm="12" md="6" lg="4">
          <v-card class="mx-auto" color="#F5F5F5" min-width="400" >
            <v-card-subtitle class="text-center">
              <strong
                >¿Por qué tus clientes te prefieren a ti antes que a tu
                competencia?</strong
              >
            </v-card-subtitle>

            <v-card-actions>
              <v-btn color="#F4511E" text @click="q1 = !q1"> Ver </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="q1 = !q1">
                <v-icon>{{
                  q1 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="q1">
                <v-divider></v-divider>

                <v-card-text>
                  <ul>
                    <li class="justify">
                      Seguir creciendo como empresario y colocar a mis empresas
                      Abadía Consulting y Zamna Soluciones Tecnológicas con
                      presencia a nivel nacional, la primera llevando los
                      proyecto de Desarrollo a la Medida y la segunda con
                      productos tecnológicos de la más alta calidad y
                      funcionalidad para la vida diaria de las empresas de
                      México.
                    </li>
                  </ul>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col class="d-flex child-flex" xs="12" sm="12" md="6" lg="4">
          <v-card class="mx-auto" color="#F5F5F5"  min-width="400">
            <v-card-subtitle class="text-center">
              <strong>¿Cuál es tu parte favorita de tu profesión? ?</strong>
            </v-card-subtitle>

            <v-card-actions>
              <v-btn color="#F4511E" text @click="q2 = !q2"> Ver </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="q2 = !q2">
                <v-icon>{{
                  q2 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="q2">
                <v-divider></v-divider>

                <v-card-text>
                  <ul>
                    <li class="justify">
                      Seguir creciendo como empresario y colocar a mis empresas
                      Abadía Consulting y Zamna Soluciones Tecnológicas con
                      presencia a nivel nacional, la primera llevando los
                      proyecto de Desarrollo a la Medida y la segunda con
                      productos tecnológicos de la más alta calidad y
                      funcionalidad para la vida diaria de las empresas de
                      México.
                    </li>
                  </ul>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>

        <v-col class="d-flex child-flex" xs="12" sm="12" md="6" lg="4">
          <v-card class="mx-auto" color="#F5F5F5"  min-width="400">
            <v-card-subtitle class="text-center">
              <strong
                >¿Por qué tus clientes te prefieren a ti antes que a tu
                competencia?</strong
              >
            </v-card-subtitle>

            <v-card-actions>
              <v-btn color="#F4511E" text @click="q3 = !q3"> Ver </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="q3 = !q3">
                <v-icon>{{
                  q3 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="q3">
                <v-divider></v-divider>

                <v-card-text>
                  <ul>
                    <li class="justify">
                      Seguir creciendo como empresario y colocar a mis empresas
                      Abadía Consulting y Zamna Soluciones Tecnológicas con
                      presencia a nivel nacional, la primera llevando los
                      proyecto de Desarrollo a la Medida y la segunda con
                      productos tecnológicos de la más alta calidad y
                      funcionalidad para la vida diaria de las empresas de
                      México.
                    </li>
                  </ul>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="d-flex child-flex" xs="12" sm="12" md="6" lg="4">
          <v-card class="mx-auto" color="#F5F5F5" min-width="400">
            <v-card-subtitle class="text-center">
              <strong
                >¿Por qué tus clientes te prefieren a ti antes que a tu
                competencia?</strong
              >
            </v-card-subtitle>

            <v-card-actions>
              <v-btn color="#F4511E" text @click="q4 = !q4"> Ver </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="q4 = !q4">
                <v-icon>{{
                  q4 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="q4">
                <v-divider></v-divider>

                <v-card-text>
                  <ul>
                    <li class="justify">
                      Seguir creciendo como empresario y colocar a mis empresas
                      Abadía Consulting y Zamna Soluciones Tecnológicas con
                      presencia a nivel nacional, la primera llevando los
                      proyecto de Desarrollo a la Medida y la segunda con
                      productos tecnológicos de la más alta calidad y
                      funcionalidad para la vida diaria de las empresas de
                      México.
                    </li>
                  </ul>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col class="d-flex child-flex" xs="12" sm="12" md="6" lg="4">
          <v-card class="mx-auto" color="#F5F5F5"  min-width="400" >
            <v-card-subtitle class="text-center">
              <strong>¿Cuál es tu parte favorita de tu profesión? ?</strong>
            </v-card-subtitle>

            <v-card-actions>
              <v-btn color="#F4511E" text @click="q5 = !q5"> Ver </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="q5 = !q5">
                <v-icon>{{
                  q5 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="q5">
                <v-divider></v-divider>

                <v-card-text>
                  <ul>
                    <li class="justify">
                      Seguir creciendo como empresario y colocar a mis empresas
                      Abadía Consulting y Zamna Soluciones Tecnológicas con
                      presencia a nivel nacional, la primera llevando los
                      proyecto de Desarrollo a la Medida y la segunda con
                      productos tecnológicos de la más alta calidad y
                      funcionalidad para la vida diaria de las empresas de
                      México.
                    </li>
                  </ul>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>

        <v-col class="d-flex child-flex" xs="12" sm="12" md="6" lg="4">
          <v-card class="mx-auto" color="#F5F5F5"  min-width="400">
            <v-card-subtitle class="text-center">
              <strong>¿Cuál es tu parte favorita de tu profesión? ?</strong>
            </v-card-subtitle>

            <v-card-actions>
              <v-btn color="#F4511E" text @click="q6 = !q6"> Ver </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="q6 = !q6">
                <v-icon>{{
                  q6 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="q6">
                <v-divider></v-divider>

                <v-card-text>
                  <ul>
                    <li class="justify">
                      Seguir creciendo como empresario y colocar a mis empresas
                      Abadía Consulting y Zamna Soluciones Tecnológicas con
                      presencia a nivel nacional, la primera llevando los
                      proyecto de Desarrollo a la Medida y la segunda con
                      productos tecnológicos de la más alta calidad y
                      funcionalidad para la vida diaria de las empresas de
                      México.
                    </li>
                  </ul>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>

      <v-row align="center" class="mx-1">
        <div class="my-2">
          <v-btn
            depressed
            large
            rounded
            color="primary white--text"
            to="/references"
            >Anterior</v-btn
          >
        </div>
        <v-spacer></v-spacer>
        <div class="my-2">
          <v-btn depressed large rounded color="primary white--text" to="/"
            >Finalizar</v-btn
          >
        </div>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import pageFooter from "@/components/Footer.vue";
import header from "@/components/header.vue";
export default {
  components: {
    pageFooter,
  },
  data() {
    return {
      text: "inin",
      q1: false,
      q2: false,
      q3: false,
      q4: false,
      q5: false,
      q6: false,
    };
  },
  methods: {},
  created() {},
};
</script>
